<template>
    <div>
    <!-- Header Start -->
        <Dheader />
	<!-- Header End -->
	<!-- Left Sidebar Start -->
        <Dnav1 />
	<!-- Left Sidebar End -->
	<!-- Body Start -->
        <div class="wrapper _bg4586">
            <div class="sa4d25">
                <div class="container-fluid">			
                    <div class="row">
                        <div class="col-lg-12">
                            <h2 class="st_title"><i class='uil uil-cog'></i> Setting</h2>
                            <div class="setting_tabs">
                                <ul class="nav nav-pills mb-4" id="pills-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="pills-account-tab" data-toggle="pill" href="#pills-account" role="tab" aria-selected="true">Account</a>
                                    </li>
                                    <!-- <li class="nav-item">
                                        <a class="nav-link" id="pills-notification-tab" data-toggle="pill" href="#pills-notification" role="tab" aria-selected="false">Notification</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-privacy-tab" data-toggle="pill" href="#pills-privacy" role="tab" aria-selected="false">Privacy</a>
                                    </li> -->
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-bllingpayment-tab" data-toggle="pill" href="#pills-bllingpayment" role="tab" aria-selected="false">Billing and Payouts</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-api-tab" data-toggle="pill" href="#pills-api" role="tab" aria-selected="false">Affiliations</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-closeaccount-tab" data-toggle="pill" href="#pills-closeaccount" role="tab" aria-selected="false">Close Account</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" style="background-color: transparent" id="pills-account" role="tabpanel" aria-labelledby="pills-account-tab">
                                    <div class="account_setting">
                                        <h4>Your Faslearn Account</h4>
                                        <p>This is your public presence on Faslearn. You need a account to upload your paid courses, comment on courses, purchased by students, or earning.</p>
                                        <div class="basic_profile">
                                            <div class="basic_ptitle">
                                                <h4>Basic Profile</h4>
                                                <p>Add information about yourself </p>
                                            </div>
                                            <div class="basic_form">
                                                <div class="row">
                                                    <div class="col-lg-8">
                                                        <div class="row">
                                                            <div class="col-lg-6">
                                                                <div class="ui search focus mt-30">
                                                                    <div class="ui left icon input swdh11 swdh19">
                                                                        <input class="prompt srch_explore" type="text" name="name" required="" maxlength="64" placeholder="First Name" v-model="firstName">															
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="ui search focus mt-30">
                                                                    <div class="ui left icon input swdh11 swdh19">
                                                                        <input class="prompt srch_explore" type="text" name="surname" required="" maxlength="64" placeholder="Last Name" v-model="lastName">															
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="ui search focus mt-30">
                                                                    <div class="ui left icon input swdh11 swdh19">
                                                                        <input class="prompt srch_explore" type="text" name="headline" required="" maxlength="60" placeholder="Address" v-model="address">															
                                                                        <div class="form-control-counter" data-purpose="form-control-counter">36</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="ui search focus mt-30">
                                                                    <div class="ui left icon input swdh11 swdh19">
                                                                        <input class="prompt srch_explore" type="number" name="headline" required="" maxlength="60" placeholder="Phone" v-model="phone">															
                                                                        <div class="form-control-counter" data-purpose="form-control-counter">36</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="divider-1"></div>
                                                            </div>
                                                            <Loading v-if="userLoading" />
                                                            <Success v-if="userSuccess" >
                                                                <ul>
                                                                    <li>Update Successful</li>
                                                                </ul>
                                                            </Success>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button class="save_btn" type="button" @click.prevent="update_user_details" v-if="!userLoading">Save Changes</button>
                                        <div class="basic_profile" v-if="myInstructorStatus!='null'">
                                            <div class="basic_ptitle">
                                                <h4>Instructor Profile</h4>
                                                <p>Edit your Instructor Details </p>
                                            </div>
                                            <div class="basic_form">
                                                <div class="row">
                                                    <div class="col-lg-8">
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="ui search focus mt-30">																
                                                                    <div class="ui form swdh30">
                                                                        <div class="field">
                                                                            <textarea rows="3" name="description" id="id_about" placeholder="expertise" v-model="expertise"></textarea>
                                                                        </div>
                                                                    </div>
                                                                    <h4>Bank details</h4>
                                                                    <div class="help-block">Bank Name</div>
                                                                    <select class="ui hj145 dropdown cntry152 prompt srch_explore" v-model="bank_Code" @change="setBankName" required>
                                                                        <option value="">Select Bank</option>
                                                                        <option :value="p.code" v-for="p in payStackBanks" :key="p">{{p.name}}</option>
                                                                    </select>
                                                                    <div class="ui left icon input swdh11 swdh19" style="display: none">
                                                                        <input class="prompt srch_explore" type="text" v-model="bank_Name" name="bankName"  @change="setBankDetailsChange()">															
                                                                    </div>
                                                                    <br>
                                                                    <div class="ui search focus">
                                                                        <div class="help-block">Account Number</div>
                                                                        <div class="ui left icon input swdh11 swdh19">
                                                                            <input class="prompt srch_explore" @change="setBankDetailsChange()" v-model="bank_AccountNumber" name="bankAccountNumber" maxlength="64" placeholder="Enter your Account Number">															
                                                                        </div>
                                                                    </div>
                                                                    <br>
                                                                    <div class="ui search focus">
                                                                        <div class="help-block">Account Name</div>
                                                                        <div class="ui left icon input swdh11 swdh19">
                                                                            <input class="prompt srch_explore" type="text" @change="setBankDetailsChange()" v-model="bank_BusinessName" name="bankBusinessName" maxlength="64" placeholder="Enter your Account Name">															
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="divider-1"></div>
                                                            </div>
                                                            <Loading v-if="instructorLoading" />
                                                            <Success v-if="instructorSuccess" >
                                                                <ul>
                                                                    <li>Update Successful</li>
                                                                </ul>
                                                            </Success>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button class="save_btn" type="button" @click.prevent="update_instructor" v-if="!instructorLoading&&myInstructorStatus!='null'">Save Changes</button>
                                    </div>
                                </div>
                                <div class="tab-pane fade" style="background-color: transparent" id="pills-notification" role="tabpanel" aria-labelledby="pills-notification-tab">
                                    <div class="account_setting">
                                        <h4>Notifications - Choose when and how to be notified</h4>
                                        <p>Select push and email notifications you'd like to receive</p>
                                        <div class="basic_profile">										
                                            <div class="basic_form">
                                                <div class="nstting_content">
                                                    <div class="basic_ptitle">
                                                        <h4>Choose when and how to be notified</h4>
                                                    </div>
                                                    <div class="ui toggle checkbox _1457s2">
                                                        <input type="checkbox" name="stream_ss1" checked>
                                                        <label>Subscriptions</label>
                                                        <p class="ml5">Notify me about activity from the profiles I'm subscribed to</p>
                                                    </div>
                                                    <div class="ui toggle checkbox _1457s2">
                                                        <input type="checkbox" name="stream_ss2">
                                                        <label>Recommended Courses</label>
                                                        <p class="ml5">Notify me of courses I might like based on what I watch</p>
                                                    </div>
                                                    <div class="ui toggle checkbox _1457s2">
                                                        <input type="checkbox" name="stream_ss3">
                                                        <label>Activity on my comments</label>
                                                        <p class="ml5">Notify me about activity on my comments on others’ courses</p>
                                                    </div>
                                                    <div class="ui toggle checkbox _1457s2">
                                                        <input type="checkbox" name="stream_ss4" checked>
                                                        <label>Replies to my comments</label>
                                                        <p class="ml5">Notify me about replies to my comments</p>
                                                    </div>																								
                                                </div>
                                            </div>
                                        </div>
                                        <div class="divider-1 mb-50"></div>
                                        <div class="basic_profile">										
                                            <div class="basic_form">
                                                <div class="nstting_content">
                                                    <div class="basic_ptitle">
                                                        <h4>Email notifications</h4>
                                                        <p>Your emails are sent to gambol943@gmail.com. To unsubscribe from an email, click the "Unsubscribe" link at the bottom of it. <a href="#">Learn more</a> about emails from Edututs+.</p>
                                                    </div>
                                                    <div class="ui toggle checkbox _1457s2">
                                                        <input type="checkbox" name="stream_ss5" checked>
                                                        <label>Send me emails about my Cursus activity and updates I requested</label>
                                                        <p class="ml5">If this setting is turned off, Cursus may still send you messages regarding your account, required service announcements, legal notifications, and privacy matters</p>
                                                    </div>
                                                    <div class="ui toggle checkbox _1457s2">
                                                        <input type="checkbox" name="stream_ss6">
                                                        <label>Promotions, course recommendations, and helpful resources from Cursus.</label>
                                                    </div>
                                                    <div class="ui toggle checkbox _1457s2">
                                                        <input type="checkbox" name="stream_ss7">
                                                        <label>Announcements from instructors whose course(s) I'm enrolled in.</label>
                                                        <p class="ml5">To adjust this preference by course, leave this box checked and go to the  course dashboard and click on "Options" to opt in or out of specific announcements.</p>
                                                    </div>																																				
                                                </div>
                                            </div>
                                        </div>
                                        <button class="save_btn" type="submit">Save Changes</button>
                                    </div>
                                </div>
                                <div class="tab-pane fade" style="background-color: transparent" id="pills-privacy" role="tabpanel" aria-labelledby="pills-privacy-tab">
                                    <div class="account_setting">
                                        <h4>Privacy</h4>
                                        <p>Modify your privacy settings here.</p>
                                        <div class="basic_profile">										
                                            <div class="basic_form">
                                                <div class="nstting_content">
                                                    <div class="basic_ptitle">
                                                        <h4>Profile page settings</h4>
                                                    </div>
                                                    <div class="ui toggle checkbox _1457s2">
                                                        <input type="checkbox" name="stream_ss8" checked>
                                                        <label>Show your profile on search engines</label>
                                                    </div>
                                                    <div class="ui toggle checkbox _1457s2">
                                                        <input type="checkbox" name="stream_ss9">
                                                        <label>Show courses you're taking on your profile page</label>
                                                    </div>																																			
                                                </div>
                                            </div>
                                        </div>	
                                        <button class="save_btn" type="submit">Save Changes</button>
                                    </div>
                                </div>
                                <div class="tab-pane fade" style="background-color: transparent" id="pills-bllingpayment" role="tabpanel" aria-labelledby="pills-bllingpayment-tab">
                                    <div class="account_setting">
                                        <h4>Billing and Payouts</h4>
                                        <p>Want to charge for a course? Provide your bank details</p>
                                        <div class="basic_form">
                                            <div class="row">
                                                <div class="col-lg-8">
                                                    <div class="basic_ptitle mt-30">
                                                        <h4>Bank Details</h4>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                            <div class="ui search focus mt-30">
                                                                <div class="ui left icon input swdh11 swdh19">
                                                                    <input class="prompt srch_explore" type="text" name="name"  required="" maxlength="64" placeholder="Bank Name" v-model="bankName">															
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <div class="ui search focus mt-30">
                                                                <div class="ui left icon input swdh11 swdh19">
                                                                    <input class="prompt srch_explore" type="number" required="" maxlength="64" placeholder="Account Number" v-model="bankAccountNumber">															
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="ui search focus mt-30">
                                                                <div class="ui left icon input swdh11 swdh19">
                                                                    <input class="prompt srch_explore" type="text"  required="" maxlength="64" placeholder="Account Name" v-model="bankAccountName">															
                                                                </div>
                                                                <div class="help-block">Please make sure all your information is correct to avoid misplaced payment.</div>
                                                            </div>
                                                        </div>
                                                    </div>	
                                                </div>	
                                            </div>	
                                        </div>
                                        <Success v-if="success">
                                            <ul>
                                                <li>Action Successful</li>
                                            </ul>
                                        </Success>
                                        <Loading v-if="Loading"/>
                                        <button class="save_btn" type="button" @click.prevent="manage_myBankDetails" v-if="(bankName!=''&&bankName!=null)&&(bankAccountNumber!=''&&bankAccountNumber!=null)&&(bankAccountName!=''&&bankAccountName!=null)&&!Loading">Save Changes</button>
                                        <!-- <div class="divider-1 mb-50"></div>
                                        <div class="basic_form">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="basic_ptitle">
                                                        <h4>Exclusive Sales</h4>
                                                        <p>Sell more of your exclusive products of this type (equal to the amount on the left) to get % cut from further exclusive sales.</p>
                                                    </div>
                                                    <div class="billing-percentages">
                                                        <div class="billing-percentages-progress-bar">
                                                            <ul class="billing-percentages-progress-bar__labels">
                                                                <li>
                                                                    <div class="billing-percentages-progress-bar__label billing-percentages-progress-bar__label_zero-level">
                                                                        <span class="billing-percentages-progress-bar__profit t5">
                                                                            <strong class="format-currency ">$0</strong>
                                                                        </span>
                                                                        <span class="billing-percentages-progress-bar__percent t5">50%</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="billing-percentages-progress-bar__label">
                                                                        <span class="billing-percentages-progress-bar__profit t5">
                                                                            <strong class="format-currency ">$2,000</strong>
                                                                        </span>
                                                                        <span class="billing-percentages-progress-bar__percent t5">53%</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="billing-percentages-progress-bar__label">
                                                                        <span class="billing-percentages-progress-bar__profit t5">
                                                                            <strong class="format-currency ">$8,000</strong>
                                                                        </span>
                                                                        <span class="billing-percentages-progress-bar__percent t5">55%</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="billing-percentages-progress-bar__label">
                                                                        <span class="billing-percentages-progress-bar__profit t5">
                                                                            <strong class="format-currency ">$18,000</strong>
                                                                        </span>
                                                                        <span class="billing-percentages-progress-bar__percent t5">58%</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="billing-percentages-progress-bar__label">
                                                                        <span class="billing-percentages-progress-bar__profit t5">
                                                                            <strong class="format-currency ">$40,000</strong>
                                                                        </span>
                                                                        <span class="billing-percentages-progress-bar__percent t5">62%</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="billing-percentages-progress-bar__label">
                                                                        <span class="billing-percentages-progress-bar__profit t5">
                                                                            <strong class="format-currency ">$75,000</strong>
                                                                        </span>
                                                                        <span class="billing-percentages-progress-bar__percent t5">70%</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="billing-percentages-progress-bar__label">
                                                                        <span class="billing-percentages-progress-bar__profit t5">
                                                                            <strong class="format-currency ">$100,000</strong>
                                                                        </span>
                                                                        <span class="billing-percentages-progress-bar__percent t5">80%</span>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="basic_form mt-50">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="basic_ptitle">
                                                        <h4>Non-Exclusive Sales</h4>
                                                        <p>Sell more of your non-exclusive products of this type (equal to the amount on the left) to get 70% cut from every non-exclusive sales.</p>
                                                    </div>
                                                    <div class="billing-percentages">
                                                        <div class="billing-percentages-progress-bar">
                                                            <ul class="billing-percentages-progress-bar__labels">
                                                                <li>
                                                                    <div class="billing-percentages-progress-bar__label billing-percentages-progress-bar__label_zero-level">
                                                                        <span class="billing-percentages-progress-bar__profit t5">
                                                                            <strong class="format-currency ">$0</strong>
                                                                        </span>
                                                                        <span class="billing-percentages-progress-bar__percent t5">30%</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="billing-percentages-progress-bar__label">
                                                                        <span class="billing-percentages-progress-bar__profit t5">
                                                                            <strong class="format-currency ">$2,000</strong>
                                                                        </span>
                                                                        <span class="billing-percentages-progress-bar__percent t5">30%</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="billing-percentages-progress-bar__label">
                                                                        <span class="billing-percentages-progress-bar__profit t5">
                                                                            <strong class="format-currency ">$8,000</strong>
                                                                        </span>
                                                                        <span class="billing-percentages-progress-bar__percent t5">30%</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="billing-percentages-progress-bar__label">
                                                                        <span class="billing-percentages-progress-bar__profit t5">
                                                                            <strong class="format-currency ">$18,000</strong>
                                                                        </span>
                                                                        <span class="billing-percentages-progress-bar__percent t5">30%</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="billing-percentages-progress-bar__label">
                                                                        <span class="billing-percentages-progress-bar__profit t5">
                                                                            <strong class="format-currency ">$40,000</strong>
                                                                        </span>
                                                                        <span class="billing-percentages-progress-bar__percent t5">30%</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="billing-percentages-progress-bar__label">
                                                                        <span class="billing-percentages-progress-bar__profit t5">
                                                                            <strong class="format-currency ">$75,000</strong>
                                                                        </span>
                                                                        <span class="billing-percentages-progress-bar__percent t5">30%</span>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div class="billing-percentages-progress-bar__label">
                                                                        <span class="billing-percentages-progress-bar__profit t5">
                                                                            <strong class="format-currency ">$100,000</strong>
                                                                        </span>
                                                                        <span class="billing-percentages-progress-bar__percent t5">30%</span>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="divider-1 mb-50"></div>
                                        <div class="basic_form mt-50">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="basic_ptitle">
                                                        <h4>Withrawal Method</h4>													
                                                    </div>												
                                                    <div class="rpt100 mt-30">													
                                                        <ul class="radio--group-inline-container_1">
                                                            <li>
                                                                <div class="radio-item_1">
                                                                    <input id="paypal1" value="payal" name="paymentmethod" type="radio" data-minimum="50.0">
                                                                    <label for="paypal1" class="radio-label_1">Paypal</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="radio-item_1">
                                                                    <input id="payoneer1" value="payoneer" name="paymentmethod" type="radio" data-minimum="50.0">
                                                                    <label  for="payoneer1" class="radio-label_1">Payoneer</label>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="radio-item_1">
                                                                    <input id="swift1" value="swift" name="paymentmethod" type="radio" data-minimum="500.0">
                                                                    <label  for="swift1" class="radio-label_1">Swift</label>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="form-group return-departure-dts" data-method="payal">															
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="pymnt_title">
                                                                    <h4>Your PayPal Account</h4>
                                                                    <span>Minimum - $50.00</span>
                                                                    <p>Get paid by credit or debit card, PayPal transfer or even via bank account in just a few clicks. All you need is your email address or mobile number. <a href="#">More about PayPal</a> | <a href="#">Create an account</a></p>
                                                                </div>
                                                            </div>														
                                                            <div class="col-lg-4 col-md-4">
                                                                <div class="ui search focus mt-30">
                                                                    <div class="ui left icon input swdh11 swdh19">
                                                                        <input class="prompt srch_explore" type="email" name="emailaddress" value="" id="id_email" required="" maxlength="64" placeholder="Email address">															
                                                                    </div>
                                                                </div>
                                                                <div class="ui search focus mt-20">
                                                                    <div class="ui left icon input swdh11 swdh19">
                                                                        <input class="prompt srch_explore" type="email" name="cemailaddress" value="" id="id_emailc" required="" maxlength="64" placeholder="Confirm email address">															
                                                                    </div>
                                                                </div>
                                                                <button class="save_payout_btn" type="submit">Set Payout Account</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group return-departure-dts" data-method="payoneer">															
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="pymnt_title">
                                                                    <h4>Your Payoneer Account</h4>
                                                                    <span>Minimum - $50.00</span>
                                                                    <p>Payoneer Prepaid MasterCard® or Global Bank Transfer (Payoneer) offers an easy, convenient and cost effective way to get paid. <a href="#">More about Payoneer </a> | <a href="#">Payoneer FAQs</a></p>
                                                                </div>
                                                            </div>														
                                                            <div class="col-lg-4 col-md-4">
                                                                <div class="ui search focus mt-30">
                                                                    <div class="ui left icon input swdh11 swdh19">
                                                                        <input class="prompt srch_explore" type="email" name="emailaddress1" value="" id="id_email1" required="" maxlength="64" placeholder="Email address">															
                                                                    </div>
                                                                </div>
                                                                <div class="ui search focus mt-20">
                                                                    <div class="ui left icon input swdh11 swdh19">
                                                                        <input class="prompt srch_explore" type="email" name="cemailaddress1" value="" id="id_emailc1" required="" maxlength="64" placeholder="Confirm email address">															
                                                                    </div>
                                                                </div>
                                                                <button class="save_payout_btn" type="submit">Set Payout Account</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group return-departure-dts" data-method="swift">															
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="pymnt_title">
                                                                    <h4>Your SWIFT Account</h4>
                                                                    <span>Minimum - $500.00</span>
                                                                    <p>SWIFT (International Transfer) get paid directly into your bank account. Connected with over 9000 banking organisations, security institutions and customers in more than 200 countries <a href="#">More about SWIFT</a></p>
                                                                </div>
                                                            </div>														
                                                            <div class="col-lg-4 col-md-4">
                                                                <div class="ui search focus mt-30">
                                                                    <div class="ui left icon input swdh11 swdh19">
                                                                        <input class="prompt srch_explore" type="text" name="fullname" value="" id="id_fullname" required="" maxlength="64" placeholder="Full Name">															
                                                                    </div>
                                                                </div>
                                                                <div class="ui search focus mt-15">
                                                                    <div class="ui left icon input swdh11 swdh19">
                                                                        <input class="prompt srch_explore" type="text" name="uraddress" value="" id="id_address4" required="" maxlength="64" placeholder="Your Address">															
                                                                    </div>
                                                                </div>
                                                                <div class="ui fluid search selection dropdown focus mt-15 cntry152">
                                                                    <input type="hidden" name="country" class="prompt srch_explore">
                                                                    <i class="dropdown icon"></i>
                                                                    <div class="default text">Select Country</div>
                                                                    <div class="menu">
                                                                        <div class="item" data-value="af"><i class="af flag"></i>Afghanistan</div>
                                                                        <div class="item" data-value="ax"><i class="ax flag"></i>Aland Islands</div>
                                                                        <div class="item" data-value="al"><i class="al flag"></i>Albania</div>
                                                                        <div class="item" data-value="dz"><i class="dz flag"></i>Algeria</div>
                                                                        <div class="item" data-value="as"><i class="as flag"></i>American Samoa</div>
                                                                        <div class="item" data-value="ad"><i class="ad flag"></i>Andorra</div>
                                                                        <div class="item" data-value="ao"><i class="ao flag"></i>Angola</div>
                                                                        <div class="item" data-value="ai"><i class="ai flag"></i>Anguilla</div>
                                                                        <div class="item" data-value="ag"><i class="ag flag"></i>Antigua</div>
                                                                        <div class="item" data-value="ar"><i class="ar flag"></i>Argentina</div>
                                                                        <div class="item" data-value="am"><i class="am flag"></i>Armenia</div>
                                                                        <div class="item" data-value="aw"><i class="aw flag"></i>Aruba</div>
                                                                        <div class="item" data-value="au"><i class="au flag"></i>Australia</div>
                                                                        <div class="item" data-value="at"><i class="at flag"></i>Austria</div>
                                                                        <div class="item" data-value="az"><i class="az flag"></i>Azerbaijan</div>
                                                                        <div class="item" data-value="bs"><i class="bs flag"></i>Bahamas</div>
                                                                        <div class="item" data-value="bh"><i class="bh flag"></i>Bahrain</div>
                                                                        <div class="item" data-value="bd"><i class="bd flag"></i>Bangladesh</div>
                                                                        <div class="item" data-value="bb"><i class="bb flag"></i>Barbados</div>
                                                                        <div class="item" data-value="by"><i class="by flag"></i>Belarus</div>
                                                                        <div class="item" data-value="be"><i class="be flag"></i>Belgium</div>
                                                                        <div class="item" data-value="bz"><i class="bz flag"></i>Belize</div>
                                                                        <div class="item" data-value="bj"><i class="bj flag"></i>Benin</div>
                                                                        <div class="item" data-value="bm"><i class="bm flag"></i>Bermuda</div>
                                                                        <div class="item" data-value="bt"><i class="bt flag"></i>Bhutan</div>
                                                                        <div class="item" data-value="bo"><i class="bo flag"></i>Bolivia</div>
                                                                        <div class="item" data-value="ba"><i class="ba flag"></i>Bosnia</div>
                                                                        <div class="item" data-value="bw"><i class="bw flag"></i>Botswana</div>
                                                                        <div class="item" data-value="bv"><i class="bv flag"></i>Bouvet Island</div>
                                                                        <div class="item" data-value="br"><i class="br flag"></i>Brazil</div>
                                                                        <div class="item" data-value="vg"><i class="vg flag"></i>British Virgin Islands</div>
                                                                        <div class="item" data-value="bn"><i class="bn flag"></i>Brunei</div>
                                                                        <div class="item" data-value="bg"><i class="bg flag"></i>Bulgaria</div>
                                                                        <div class="item" data-value="bf"><i class="bf flag"></i>Burkina Faso</div>
                                                                        <div class="item" data-value="mm"><i class="mm flag"></i>Burma</div>
                                                                        <div class="item" data-value="bi"><i class="bi flag"></i>Burundi</div>
                                                                        <div class="item" data-value="tc"><i class="tc flag"></i>Caicos Islands</div>
                                                                        <div class="item" data-value="kh"><i class="kh flag"></i>Cambodia</div>
                                                                        <div class="item" data-value="cm"><i class="cm flag"></i>Cameroon</div>
                                                                        <div class="item" data-value="ca"><i class="ca flag"></i>Canada</div>
                                                                        <div class="item" data-value="cv"><i class="cv flag"></i>Cape Verde</div>
                                                                        <div class="item" data-value="ky"><i class="ky flag"></i>Cayman Islands</div>
                                                                        <div class="item" data-value="cf"><i class="cf flag"></i>Central African Republic</div>
                                                                        <div class="item" data-value="td"><i class="td flag"></i>Chad</div>
                                                                        <div class="item" data-value="cl"><i class="cl flag"></i>Chile</div>
                                                                        <div class="item" data-value="cn"><i class="cn flag"></i>China</div>
                                                                        <div class="item" data-value="cx"><i class="cx flag"></i>Christmas Island</div>
                                                                        <div class="item" data-value="cc"><i class="cc flag"></i>Cocos Islands</div>
                                                                        <div class="item" data-value="co"><i class="co flag"></i>Colombia</div>
                                                                        <div class="item" data-value="km"><i class="km flag"></i>Comoros</div>
                                                                        <div class="item" data-value="cg"><i class="cg flag"></i>Congo Brazzaville</div>
                                                                        <div class="item" data-value="cd"><i class="cd flag"></i>Congo</div>
                                                                        <div class="item" data-value="ck"><i class="ck flag"></i>Cook Islands</div>
                                                                        <div class="item" data-value="cr"><i class="cr flag"></i>Costa Rica</div>
                                                                        <div class="item" data-value="ci"><i class="ci flag"></i>Cote Divoire</div>
                                                                        <div class="item" data-value="hr"><i class="hr flag"></i>Croatia</div>
                                                                        <div class="item" data-value="cu"><i class="cu flag"></i>Cuba</div>
                                                                        <div class="item" data-value="cy"><i class="cy flag"></i>Cyprus</div>
                                                                        <div class="item" data-value="cz"><i class="cz flag"></i>Czech Republic</div>
                                                                        <div class="item" data-value="dk"><i class="dk flag"></i>Denmark</div>
                                                                        <div class="item" data-value="dj"><i class="dj flag"></i>Djibouti</div>
                                                                        <div class="item" data-value="dm"><i class="dm flag"></i>Dominica</div>
                                                                        <div class="item" data-value="do"><i class="do flag"></i>Dominican Republic</div>
                                                                        <div class="item" data-value="ec"><i class="ec flag"></i>Ecuador</div>
                                                                        <div class="item" data-value="eg"><i class="eg flag"></i>Egypt</div>
                                                                        <div class="item" data-value="sv"><i class="sv flag"></i>El Salvador</div>
                                                                        <div class="item" data-value="gb"><i class="gb flag"></i>England</div>
                                                                        <div class="item" data-value="gq"><i class="gq flag"></i>Equatorial Guinea</div>
                                                                        <div class="item" data-value="er"><i class="er flag"></i>Eritrea</div>
                                                                        <div class="item" data-value="ee"><i class="ee flag"></i>Estonia</div>
                                                                        <div class="item" data-value="et"><i class="et flag"></i>Ethiopia</div>
                                                                        <div class="item" data-value="eu"><i class="eu flag"></i>European Union</div>
                                                                        <div class="item" data-value="fk"><i class="fk flag"></i>Falkland Islands</div>
                                                                        <div class="item" data-value="fo"><i class="fo flag"></i>Faroe Islands</div>
                                                                        <div class="item" data-value="fj"><i class="fj flag"></i>Fiji</div>
                                                                        <div class="item" data-value="fi"><i class="fi flag"></i>Finland</div>
                                                                        <div class="item" data-value="fr"><i class="fr flag"></i>France</div>
                                                                        <div class="item" data-value="gf"><i class="gf flag"></i>French Guiana</div>
                                                                        <div class="item" data-value="pf"><i class="pf flag"></i>French Polynesia</div>
                                                                        <div class="item" data-value="tf"><i class="tf flag"></i>French Territories</div>
                                                                        <div class="item" data-value="ga"><i class="ga flag"></i>Gabon</div>
                                                                        <div class="item" data-value="gm"><i class="gm flag"></i>Gambia</div>
                                                                        <div class="item" data-value="ge"><i class="ge flag"></i>Georgia</div>
                                                                        <div class="item" data-value="de"><i class="de flag"></i>Germany</div>
                                                                        <div class="item" data-value="gh"><i class="gh flag"></i>Ghana</div>
                                                                        <div class="item" data-value="gi"><i class="gi flag"></i>Gibraltar</div>
                                                                        <div class="item" data-value="gr"><i class="gr flag"></i>Greece</div>
                                                                        <div class="item" data-value="gl"><i class="gl flag"></i>Greenland</div>
                                                                        <div class="item" data-value="gd"><i class="gd flag"></i>Grenada</div>
                                                                        <div class="item" data-value="gp"><i class="gp flag"></i>Guadeloupe</div>
                                                                        <div class="item" data-value="gu"><i class="gu flag"></i>Guam</div>
                                                                        <div class="item" data-value="gt"><i class="gt flag"></i>Guatemala</div>
                                                                        <div class="item" data-value="gw"><i class="gw flag"></i>Guinea-Bissau</div>
                                                                        <div class="item" data-value="gn"><i class="gn flag"></i>Guinea</div>
                                                                        <div class="item" data-value="gy"><i class="gy flag"></i>Guyana</div>
                                                                        <div class="item" data-value="ht"><i class="ht flag"></i>Haiti</div>
                                                                        <div class="item" data-value="hm"><i class="hm flag"></i>Heard Island</div>
                                                                        <div class="item" data-value="hn"><i class="hn flag"></i>Honduras</div>
                                                                        <div class="item" data-value="hk"><i class="hk flag"></i>Hong Kong</div>
                                                                        <div class="item" data-value="hu"><i class="hu flag"></i>Hungary</div>
                                                                        <div class="item" data-value="is"><i class="is flag"></i>Iceland</div>
                                                                        <div class="item" data-value="in"><i class="in flag"></i>India</div>
                                                                        <div class="item" data-value="io"><i class="io flag"></i>Indian Ocean Territory</div>
                                                                        <div class="item" data-value="id"><i class="id flag"></i>Indonesia</div>
                                                                        <div class="item" data-value="ir"><i class="ir flag"></i>Iran</div>
                                                                        <div class="item" data-value="iq"><i class="iq flag"></i>Iraq</div>
                                                                        <div class="item" data-value="ie"><i class="ie flag"></i>Ireland</div>
                                                                        <div class="item" data-value="il"><i class="il flag"></i>Israel</div>
                                                                        <div class="item" data-value="it"><i class="it flag"></i>Italy</div>
                                                                        <div class="item" data-value="jm"><i class="jm flag"></i>Jamaica</div>
                                                                        <div class="item" data-value="jp"><i class="jp flag"></i>Japan</div>
                                                                        <div class="item" data-value="jo"><i class="jo flag"></i>Jordan</div>
                                                                        <div class="item" data-value="kz"><i class="kz flag"></i>Kazakhstan</div>
                                                                        <div class="item" data-value="ke"><i class="ke flag"></i>Kenya</div>
                                                                        <div class="item" data-value="ki"><i class="ki flag"></i>Kiribati</div>
                                                                        <div class="item" data-value="kw"><i class="kw flag"></i>Kuwait</div>
                                                                        <div class="item" data-value="kg"><i class="kg flag"></i>Kyrgyzstan</div>
                                                                        <div class="item" data-value="la"><i class="la flag"></i>Laos</div>
                                                                        <div class="item" data-value="lv"><i class="lv flag"></i>Latvia</div>
                                                                        <div class="item" data-value="lb"><i class="lb flag"></i>Lebanon</div>
                                                                        <div class="item" data-value="ls"><i class="ls flag"></i>Lesotho</div>
                                                                        <div class="item" data-value="lr"><i class="lr flag"></i>Liberia</div>
                                                                        <div class="item" data-value="ly"><i class="ly flag"></i>Libya</div>
                                                                        <div class="item" data-value="li"><i class="li flag"></i>Liechtenstein</div>
                                                                        <div class="item" data-value="lt"><i class="lt flag"></i>Lithuania</div>
                                                                        <div class="item" data-value="lu"><i class="lu flag"></i>Luxembourg</div>
                                                                        <div class="item" data-value="mo"><i class="mo flag"></i>Macau</div>
                                                                        <div class="item" data-value="mk"><i class="mk flag"></i>Macedonia</div>
                                                                        <div class="item" data-value="mg"><i class="mg flag"></i>Madagascar</div>
                                                                        <div class="item" data-value="mw"><i class="mw flag"></i>Malawi</div>
                                                                        <div class="item" data-value="my"><i class="my flag"></i>Malaysia</div>
                                                                        <div class="item" data-value="mv"><i class="mv flag"></i>Maldives</div>
                                                                        <div class="item" data-value="ml"><i class="ml flag"></i>Mali</div>
                                                                        <div class="item" data-value="mt"><i class="mt flag"></i>Malta</div>
                                                                        <div class="item" data-value="mh"><i class="mh flag"></i>Marshall Islands</div>
                                                                        <div class="item" data-value="mq"><i class="mq flag"></i>Martinique</div>
                                                                        <div class="item" data-value="mr"><i class="mr flag"></i>Mauritania</div>
                                                                        <div class="item" data-value="mu"><i class="mu flag"></i>Mauritius</div>
                                                                        <div class="item" data-value="yt"><i class="yt flag"></i>Mayotte</div>
                                                                        <div class="item" data-value="mx"><i class="mx flag"></i>Mexico</div>
                                                                        <div class="item" data-value="fm"><i class="fm flag"></i>Micronesia</div>
                                                                        <div class="item" data-value="md"><i class="md flag"></i>Moldova</div>
                                                                        <div class="item" data-value="mc"><i class="mc flag"></i>Monaco</div>
                                                                        <div class="item" data-value="mn"><i class="mn flag"></i>Mongolia</div>
                                                                        <div class="item" data-value="me"><i class="me flag"></i>Montenegro</div>
                                                                        <div class="item" data-value="ms"><i class="ms flag"></i>Montserrat</div>
                                                                        <div class="item" data-value="ma"><i class="ma flag"></i>Morocco</div>
                                                                        <div class="item" data-value="mz"><i class="mz flag"></i>Mozambique</div>
                                                                        <div class="item" data-value="na"><i class="na flag"></i>Namibia</div>
                                                                        <div class="item" data-value="nr"><i class="nr flag"></i>Nauru</div>
                                                                        <div class="item" data-value="np"><i class="np flag"></i>Nepal</div>
                                                                        <div class="item" data-value="an"><i class="an flag"></i>Netherlands Antilles</div>
                                                                        <div class="item" data-value="nl"><i class="nl flag"></i>Netherlands</div>
                                                                        <div class="item" data-value="nc"><i class="nc flag"></i>New Caledonia</div>
                                                                        <div class="item" data-value="pg"><i class="pg flag"></i>New Guinea</div>
                                                                        <div class="item" data-value="nz"><i class="nz flag"></i>New Zealand</div>
                                                                        <div class="item" data-value="ni"><i class="ni flag"></i>Nicaragua</div>
                                                                        <div class="item" data-value="ne"><i class="ne flag"></i>Niger</div>
                                                                        <div class="item" data-value="ng"><i class="ng flag"></i>Nigeria</div>
                                                                        <div class="item" data-value="nu"><i class="nu flag"></i>Niue</div>
                                                                        <div class="item" data-value="nf"><i class="nf flag"></i>Norfolk Island</div>
                                                                        <div class="item" data-value="kp"><i class="kp flag"></i>North Korea</div>
                                                                        <div class="item" data-value="mp"><i class="mp flag"></i>Northern Mariana Islands</div>
                                                                        <div class="item" data-value="no"><i class="no flag"></i>Norway</div>
                                                                        <div class="item" data-value="om"><i class="om flag"></i>Oman</div>
                                                                        <div class="item" data-value="pk"><i class="pk flag"></i>Pakistan</div>
                                                                        <div class="item" data-value="pw"><i class="pw flag"></i>Palau</div>
                                                                        <div class="item" data-value="ps"><i class="ps flag"></i>Palestine</div>
                                                                        <div class="item" data-value="pa"><i class="pa flag"></i>Panama</div>
                                                                        <div class="item" data-value="py"><i class="py flag"></i>Paraguay</div>
                                                                        <div class="item" data-value="pe"><i class="pe flag"></i>Peru</div>
                                                                        <div class="item" data-value="ph"><i class="ph flag"></i>Philippines</div>
                                                                        <div class="item" data-value="pn"><i class="pn flag"></i>Pitcairn Islands</div>
                                                                        <div class="item" data-value="pl"><i class="pl flag"></i>Poland</div>
                                                                        <div class="item" data-value="pt"><i class="pt flag"></i>Portugal</div>
                                                                        <div class="item" data-value="pr"><i class="pr flag"></i>Puerto Rico</div>
                                                                        <div class="item" data-value="qa"><i class="qa flag"></i>Qatar</div>
                                                                        <div class="item" data-value="re"><i class="re flag"></i>Reunion</div>
                                                                        <div class="item" data-value="ro"><i class="ro flag"></i>Romania</div>
                                                                        <div class="item" data-value="ru"><i class="ru flag"></i>Russia</div>
                                                                        <div class="item" data-value="rw"><i class="rw flag"></i>Rwanda</div>
                                                                        <div class="item" data-value="sh"><i class="sh flag"></i>Saint Helena</div>
                                                                        <div class="item" data-value="kn"><i class="kn flag"></i>Saint Kitts and Nevis</div>
                                                                        <div class="item" data-value="lc"><i class="lc flag"></i>Saint Lucia</div>
                                                                        <div class="item" data-value="pm"><i class="pm flag"></i>Saint Pierre</div>
                                                                        <div class="item" data-value="vc"><i class="vc flag"></i>Saint Vincent</div>
                                                                        <div class="item" data-value="ws"><i class="ws flag"></i>Samoa</div>
                                                                        <div class="item" data-value="sm"><i class="sm flag"></i>San Marino</div>
                                                                        <div class="item" data-value="gs"><i class="gs flag"></i>Sandwich Islands</div>
                                                                        <div class="item" data-value="st"><i class="st flag"></i>Sao Tome</div>
                                                                        <div class="item" data-value="sa"><i class="sa flag"></i>Saudi Arabia</div>
                                                                        <div class="item" data-value="sn"><i class="sn flag"></i>Senegal</div>
                                                                        <div class="item" data-value="cs"><i class="cs flag"></i>Serbia</div>
                                                                        <div class="item" data-value="rs"><i class="rs flag"></i>Serbia</div>
                                                                        <div class="item" data-value="sc"><i class="sc flag"></i>Seychelles</div>
                                                                        <div class="item" data-value="sl"><i class="sl flag"></i>Sierra Leone</div>
                                                                        <div class="item" data-value="sg"><i class="sg flag"></i>Singapore</div>
                                                                        <div class="item" data-value="sk"><i class="sk flag"></i>Slovakia</div>
                                                                        <div class="item" data-value="si"><i class="si flag"></i>Slovenia</div>
                                                                        <div class="item" data-value="sb"><i class="sb flag"></i>Solomon Islands</div>
                                                                        <div class="item" data-value="so"><i class="so flag"></i>Somalia</div>
                                                                        <div class="item" data-value="za"><i class="za flag"></i>South Africa</div>
                                                                        <div class="item" data-value="kr"><i class="kr flag"></i>South Korea</div>
                                                                        <div class="item" data-value="es"><i class="es flag"></i>Spain</div>
                                                                        <div class="item" data-value="lk"><i class="lk flag"></i>Sri Lanka</div>
                                                                        <div class="item" data-value="sd"><i class="sd flag"></i>Sudan</div>
                                                                        <div class="item" data-value="sr"><i class="sr flag"></i>Suriname</div>
                                                                        <div class="item" data-value="sj"><i class="sj flag"></i>Svalbard</div>
                                                                        <div class="item" data-value="sz"><i class="sz flag"></i>Swaziland</div>
                                                                        <div class="item" data-value="se"><i class="se flag"></i>Sweden</div>
                                                                        <div class="item" data-value="ch"><i class="ch flag"></i>Switzerland</div>
                                                                        <div class="item" data-value="sy"><i class="sy flag"></i>Syria</div>
                                                                        <div class="item" data-value="tw"><i class="tw flag"></i>Taiwan</div>
                                                                        <div class="item" data-value="tj"><i class="tj flag"></i>Tajikistan</div>
                                                                        <div class="item" data-value="tz"><i class="tz flag"></i>Tanzania</div>
                                                                        <div class="item" data-value="th"><i class="th flag"></i>Thailand</div>
                                                                        <div class="item" data-value="tl"><i class="tl flag"></i>Timorleste</div>
                                                                        <div class="item" data-value="tg"><i class="tg flag"></i>Togo</div>
                                                                        <div class="item" data-value="tk"><i class="tk flag"></i>Tokelau</div>
                                                                        <div class="item" data-value="to"><i class="to flag"></i>Tonga</div>
                                                                        <div class="item" data-value="tt"><i class="tt flag"></i>Trinidad</div>
                                                                        <div class="item" data-value="tn"><i class="tn flag"></i>Tunisia</div>
                                                                        <div class="item" data-value="tr"><i class="tr flag"></i>Turkey</div>
                                                                        <div class="item" data-value="tm"><i class="tm flag"></i>Turkmenistan</div>
                                                                        <div class="item" data-value="tv"><i class="tv flag"></i>Tuvalu</div>
                                                                        <div class="item" data-value="ug"><i class="ug flag"></i>Uganda</div>
                                                                        <div class="item" data-value="ua"><i class="ua flag"></i>Ukraine</div>
                                                                        <div class="item" data-value="ae"><i class="ae flag"></i>United Arab Emirates</div>
                                                                        <div class="item" data-value="us"><i class="us flag"></i>United States</div>
                                                                        <div class="item" data-value="uy"><i class="uy flag"></i>Uruguay</div>
                                                                        <div class="item" data-value="um"><i class="um flag"></i>Us Minor Islands</div>
                                                                        <div class="item" data-value="vi"><i class="vi flag"></i>Us Virgin Islands</div>
                                                                        <div class="item" data-value="uz"><i class="uz flag"></i>Uzbekistan</div>
                                                                        <div class="item" data-value="vu"><i class="vu flag"></i>Vanuatu</div>
                                                                        <div class="item" data-value="va"><i class="va flag"></i>Vatican City</div>
                                                                        <div class="item" data-value="ve"><i class="ve flag"></i>Venezuela</div>
                                                                        <div class="item" data-value="vn"><i class="vn flag"></i>Vietnam</div>
                                                                        <div class="item" data-value="wf"><i class="wf flag"></i>Wallis and Futuna</div>
                                                                        <div class="item" data-value="eh"><i class="eh flag"></i>Western Sahara</div>
                                                                        <div class="item" data-value="ye"><i class="ye flag"></i>Yemen</div>
                                                                        <div class="item" data-value="zm"><i class="zm flag"></i>Zambia</div>
                                                                        <div class="item" data-value="zw"><i class="zw flag"></i>Zimbabwe</div>
                                                                    </div>
                                                                </div>
                                                                <div class="ui search focus mt-15">
                                                                    <div class="ui left icon input swdh11 swdh19">
                                                                        <input class="prompt srch_explore" type="text" name="swiftcode" value="" id="id_swiftcode" required="" maxlength="64" placeholder="Swift-Code">															
                                                                    </div>
                                                                </div>
                                                                <div class="ui search focus mt-15">
                                                                    <div class="ui left icon input swdh11 swdh19">
                                                                        <input class="prompt srch_explore" type="text" name="banknumber" value="" id="id_banknumber" required="" maxlength="64" placeholder="Back Account Number">															
                                                                    </div>
                                                                </div>
                                                                <div class="ui search focus mt-15">
                                                                    <div class="ui left icon input swdh11 swdh19">
                                                                        <input class="prompt srch_explore" type="text" name="bankname" value="" id="id_bankname" required="" maxlength="64" placeholder="Back Name">															
                                                                    </div>
                                                                </div>
                                                                <div class="ui search focus mt-15">
                                                                    <div class="ui left icon input swdh11 swdh19">
                                                                        <input class="prompt srch_explore" type="text" name="address5" value="" id="id_address5" required="" maxlength="64" placeholder="Back Address">															
                                                                    </div>
                                                                </div>
                                                                <button class="save_payout_btn" type="submit">Set Payout Account</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        
                                    </div>
                                </div>
                                <div class="tab-pane fade" style="background-color: transparent" id="pills-api" role="tabpanel" aria-labelledby="pills-api-tab">								
                                    <div class="account_setting">
                                        <h4>Affiliate Links</h4>
                                        <p>The Faslearn Affiliate Link exposes users to be able to earn more by sharing faslearns affiliate links on there social media platforms</p>
                                        <h5>How to start</h5>
                                        <ul>
                                            <li><i class="uil uil-arrow-right"></i> Copy the link below</li>
                                            <li><i class="uil uil-arrow-right"></i> Share on all your social media platforms</li>
                                            <li><i class="uil uil-arrow-right"></i> Any one of your friends who registers through your link becomes your downline</li>
                                            <li><i class="uil uil-arrow-right"></i> For each of your downlines purchace on Faslearn, you earn 10%</li>
                                            <li><i class="uil uil-arrow-right"></i> You can request for payouts when your earning is ₦10,000 and above</li>
                                        </ul>
                                    </div>
                                    <button class="api_btn" @click="copyURL('https://faslearn.com?affiliateUserRef='+ userId )">Copy Your Affiliate Link</button>
                                    <div class="nt_apt"><i class="uil uil-info-circle" style="color: green"></i> https://faslearn.com?affiliateUserRef={{userId}} </div>
                                    <router-link to="/Dpayout">
                                        <button class="api_btn">Apply for payouts here</button>	
                                    </router-link>							
                                </div>
                                <div class="tab-pane fade" style="background-color: transparent" id="pills-closeaccount" role="tabpanel" aria-labelledby="pills-closeaccount-tab">
                                    <div class="account_setting">
                                        <h4>Close account</h4>
                                        <p><strong>Warning:</strong> If you close your account, you will be unsubscribed from all your 5 courses, and will lose access forever.</p>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <div class="ui search focus mt-30">
                                                <div class="ui left icon input swdh11 swdh19">
                                                    <input class="prompt srch_explore" type="password" name="yourassword" id="id_yourpassword" required="" maxlength="64" placeholder="Enter Your Password">															
                                                </div>
                                                <div class="help-block">Are you sure you want to close your account?</div>
                                            </div>
                                            <button class="save_payout_btn mbs20" type="submit">You can not close your account for now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>						
                    </div>
                </div>
            </div>


            <br><br><br><br>
            <Dfooter />
        </div>
	<!-- Body End -->
    </div>
</template>
<script>
import Dheader from '@/components/dashboard/Header.vue'
import Dnav1 from '@/components/dashboard/Nav1.vue'
import Dfooter from '@/components/dashboard/Footer.vue'
import Loading from '@/components/Loading.vue'
import Success from "@/components/Success.vue"
import { mapActions, mapGetters } from 'vuex'
export default {
	name: 'Dteachers',
	data(){
		return{
            user: '',
            instructor: '',
            categories: '',
            bankName: null,
            bankAccountName: null,
            bankAccountNumber: null,
            userId: '',
            results: '',
            Loading: false,
            success: false,
            userLoading: false,
            userSuccess: false,
            instructorLoading: false,
            instructorSuccess: false,
            error : '',
            firstName: '',
            lastName: '',
            phone: '',
            address: '',
            category: '', 
            subCategory: '', 
            expertise: '', 
            experience: '',
            bank_Code: '',
            bank_Name: '',
            bank_BusinessName: '',
            bank_AccountNumber: '',
            payStackBanks: '',
            paystackId: '',
            bankDetailsChanged: false
		}
	},
    components: {
        Dheader,
        Dnav1,
        Loading,
        Success,
		Dfooter
	},
	computed: {
        ...mapGetters(['isInstructor']),
        ...mapGetters(['isAdmin']),
        ...mapGetters(['myInstructorStatus']),
        ...mapGetters(['myAdminStatus']),
	},
	methods: {
		...mapActions(['fetch_categories']),
        ...mapActions(['fetchOneInstructor','fetch_ForSettingsEditInstructor']),
        ...mapActions(['fetch_user']),
        ...mapActions(['manage_BankDetails']),
        ...mapActions(['fetch_UserBankDetails']),
        ...mapActions(['user_Update_account']),
        ...mapActions(['registerInstructor']),
        ...mapActions(['fetchPayStackBanks','update_PayStackSubAccountDetails']),
        manage_myBankDetails(){
            this.Loading = true
            let object = {
                userId : this.userId, 
                bankName : this.bankName, 
                accountName : this.bankAccountName, 
                accountNumber : this.bankAccountNumber
            }
            this.manage_BankDetails(object)
            .then(res => {
                if (res.data.success){
                    this.Loading = false
                    this.success = true
                }
            })

        },
        update_user_details(){
            if (this.validateUser()) {
                this.userLoading = true
                let user = {
                    userId : this.userId,
                    firstName : this.firstName,
                    lastName : this.lastName,
                    address : this.address,
                    phone : this.phone
                }
                this.user_Update_account(user)
                .then(res => {
                    if (res.data.isSuccess) {
                        this.userLoading = false
                        this.userSuccess = true
                    }
                })    
            }
        },
        validateUser(){
			let flag = true;
            let error = ""
			if(this.userId==''){
				flag=false;
				error= 'userId can not be left empty'
            }
            if(this.firstName==''){
				flag=false;
				error= 'firstName can not be left empty'
            }
            if(this.lastName==''){
				flag=false;
				error= 'lastName can not be left empty'
            }
            if(this.address==''){
				flag=false;
				error= 'address can not be left empty'
            }
            if(this.phone==''){
				flag=false;
				error= 'phone can not be left empty'
            }
			if (flag == true) {
				return true
			}else{
                alert(error)
				return false
			}
        },
        setBankName(){
            this.bank_Name = ''
            for (let i = 0; i < this.payStackBanks.length; i++) {
                const element = this.payStackBanks[i];
                if (element.code == this.bank_Code) {
                    this.bank_Name = element.name
                }
                
            }
            this.setBankDetailsChange()
        },
        setBankDetailsChange(){
            if (this.paystackId=='false'||this.paystackId==false||this.paystackId==''||this.paystackId==0) {
                this.bankDetailsChanged = false
            }else{  
                this.bankDetailsChanged = true
            }
        },
        update_instructor(){
            if (this.validateInstructor()) {
                this.instructorLoading = true
                let instructor = {
                    user_id: this.userId,
                    expertise: this.expertise,
                    bankCode: this.bank_Code,
                    bankName: this.bank_Name,
                    bankAccountNumber: this.bank_AccountNumber,
                    bankBusinessName: this.bank_BusinessName
                }
                this.registerInstructor(instructor)
                .then(res => {
                    if (res.data.isInstructor) {

                        
                        if (this.bankDetailsChanged) {
                            if (this.paystackId!=0&&this.paystackId!=''&&this.paystackId!=null) {
                                let paystackObject = {
                                    business_name: this.bank_BusinessName, 
                                    bank_code: this.bank_Code, 
                                    account_number: this.bank_AccountNumber, 
                                    paystackId: this.paystackId
                                }
                                this.update_PayStackSubAccountDetails(paystackObject)
                                .then(res => {
                                    console.log(res.data)
                                    this.instructorLoading = false
                                    this.instructorSuccess = true
                                })
                                .catch(err => {
                                    alert('something went wrong, make changes and save again')
                                    console.log(err)
                                })
                                
                            }else{
                                this.instructorLoading = false
                                alert('paystack id issues')
                            }
                        }else{ 
                            this.instructorLoading = false
                            this.instructorSuccess = true
                        }
                    }
                })
            }
        },

        validateAccountNumber(){
            var splitted = this.bank_AccountNumber.split("");
            var stringflag = false
            var value
            for (let i = 0; i < splitted.length; i++) {
                value = /^\d+$/.test(splitted[i])
                if (!value){
                    stringflag = true
                }
            }
            if (stringflag) {
                return false
            }else{
                return true
            }
        },
        
        async copyURL(mytext) {
            try {
            await navigator.clipboard.writeText(mytext);
            alert('Copied');
            } catch($e) {
            alert('Cannot copy');
            }
        },

        validateInstructor(){
			let flag = true;
            let error = ""
			if(this.expertise==''){
				flag=false;
				error= 'expertise can not be left empty'
            }
            if(this.bank_Code==''){
				flag=false;
				error= 'bank_Code can not be left empty'
            }
            if(this.bank_Name==''){
				flag=false;
				error= 'bank_Name can not be left empty'
            }
            if(this.bank_AccountNumber==''){
				flag=false;
				error= 'bank_AccountNumber can not be left empty'
            }
            if(this.bank_BusinessName==''){
				flag=false;
				error= 'bank_BusinessName can not be left empty'
            }
            if (!this.validateAccountNumber()) {
                flag=false;
				error= 'bank_AccountNumber must be all numbers'
            }
			if (flag == true) {
				return true
			}else{
                alert(error)
				return false
			}
        }
        
	},
    mounted(){
        this.userId = localStorage.getItem('fmruser')
        //this.isInstructor
		if (this.myInstructorStatus!='null') {
            this.fetch_ForSettingsEditInstructor(this.userId) 
            .then(res => {
                this.instructor = res.data[0],
                this.category= res.data[0].category, 
                this.subCategory= res.data[0].subCategory, 
                this.expertise= res.data[0].expertise, 
                this.experience= res.data[0].experience
                this.bank_BusinessName = res.data[0].business_name
                this.bank_Code = res.data[0].bank_code
                this.bank_Name = res.data[0].bank_name
                this.bank_AccountNumber = res.data[0].account_number
                this.paystackId = res.data[0].paystackId
            })
        }
        this.fetch_user(this.userId)
        .then(res => {
            this.user = res.data.user
            this.firstName= res.data.user.firstName
            this.lastName= res.data.user.lastName
            this.phone= res.data.user.phone
            this.address= res.data.user.address
        })
        this.fetch_UserBankDetails(this.userId)
        .then(res => {
            this.results = res.data
            if (this.results.length != 0) {
                this.bankName = res.data[0].bankName
                this.bankAccountName = res.data[0].accountName 
                this.bankAccountNumber = res.data[0].accountNumber
            }
        })
        this.fetchPayStackBanks()
        .then(res => {
            this.payStackBanks = res.data.data
        })
        .catch(err => {
            console.log(err)
        })
    }
}
</script>
<style scoped>
    @import url("./assets/css/style.css");
    /* @import url("https://fonts.googleapis.com/css?family=Roboto:400,700,500"); */
    @import url("./assets/vendor/unicons-2.0.1/css/unicons.css");
    @import url("./assets/css/instructor-dashboard.css");
    @import url("./assets/css/vertical-responsive-menu.min.css");
    @import url("./assets/css/instructor-dashboard.css");
    @import url("./assets/css/instructor-responsive.css");
    @import url("./assets/css/responsive.css");
    @import url("./assets/css/night-mode.css");
    @import url("./assets/vendor/fontawesome-free/css/all.min.css");
    @import url("./assets/vendor/OwlCarousel/assets/owl.carousel.css");
    @import url("./assets/vendor/OwlCarousel/assets/owl.theme.default.min.css");
    @import url("./assets/vendor/bootstrap/css/bootstrap.min.css");
    @import url("./assets/vendor/semantic/semantic.min.css");

   
	

</style>